<template>
  <div>
    <div style="padding: 20px">
      设备IMEI：<el-input style="width:500px" v-model="imei" placeholder="请输入IMEI"></el-input>
    </div>
    <div>
      <el-tabs @tab-click="tabClick" :value="msgId" :tab-position="tabPosition">
        <el-tab-pane name="0X03" label="自动休眠(0X03)">
          <el-form>
            <el-form-item label="WIFI-MAC列表，一行一个MAC，带符号">
              <div>
                <br>
                示例: <br>
                8C:AB:8E:BC:F2:B8
                <br>
                00:0C:29:D9:10:96
              </div>
              <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入参数"
                  v-model="params['0X03'].macList">
              </el-input>
            </el-form-item>
            <el-form-item label="定时扫描时间，单位分钟，范围1-255">
              <el-input-number :min="1" :max="255" v-model="params['0X03'].scanTime"></el-input-number>
            </el-form-item>
            <el-form-item label="匹配个数，范围1-255" >
              <el-input-number :min="1" :max="255" v-model="params['0X03'].matches"></el-input-number>
            </el-form-item>
            <el-form-item label="连续匹配次数，范围1-255" >
              <el-input-number :min="1" :max="255" v-model="params['0X03'].continuousTimes"></el-input-number>
            </el-form-item>
          </el-form>

        </el-tab-pane>

        <el-tab-pane name="0X63" label="设备灯控制(0X63)">
          <el-form>
            <el-form-item label="闪烁模式">
              <el-input-number :min="1" :max="255" v-model="params['0X63'].ledFlashingMode"></el-input-number>
            </el-form-item>
            <el-form-item label="红灯开关">
              <el-switch
                  v-model="params['0X63'].redLedSwitch">
              </el-switch>
            </el-form-item>
            <el-form-item label="蓝灯开关">
              <el-switch
                  v-model="params['0X63'].blueLedSwitch">
              </el-switch>
            </el-form-item>

            <el-form-item label="黄灯开关">
              <el-switch
                  v-model="params['0X63'].yellowLedSwitch">
              </el-switch>
            </el-form-item>
          </el-form>

        </el-tab-pane>

      </el-tabs>
    </div>
    <div style="width:100%;text-align: center">
      <el-button @click="onSubmit" type="primary">发送指令</el-button>
    </div>
  </div>

</template>

<script>
// import {send} from "../../api/suogong";

export default {
  name: "index",
  data() {
    return {
      tabPosition: 'left',
      imei:"865917000796205",
      msgId:"0X03",
      params:{
        "0X03": {
          macList:"8C:AB:8E:BC:F2:B8\n" +
              "00:0C:29:D9:10:96",
          scanTime:5,
          matches:1,
          continuousTimes:3
        },
        "0X63":{
          ledFlashingMode:1,
          redLedSwitch:true,
          blueLedSwitch:true,
          yellowLedSwitch:true
        }
      }
    };
  },
  methods:{
    tabClick(tab){
      this.msgId = tab.name
      window.console.log(tab.name);
    },
    onSubmit(){
      let imei = this.imei;
      if(!imei){
        this.$message.error("请输入IMEI!")
        return;
      }
      let msgId = this.msgId;
      let param = null;
      let hex = "";
      param = this.params[msgId];
      window.console.log(param)

      if(msgId == "0X03"){
        hex = this.get0X03Hex(param);
        if(hex == null){
          return;
        }
        hex = this.getBCDTime() + hex;

      }if(msgId == "0X63"){
        hex = this.get0X63Hex(param);
        if(hex == null){
          return;
        }
        hex = this.getBCDTime() + hex;
      }else{
        if(param == null || param==""){
          this.$message.error("请输入参数!")
          return;
        }
      }

      hex = "7878" + this.toHexString((hex.length+2) / 2,2) + (msgId.replace("0X","")) + hex + "0D0A";
      window.console.log({imei,msgId,hex})
      window.open('http://server-sg3.youshusoft.com:8082/order/test/' + imei + "/" + hex,'_blank')
      // send(imei,hex).then(() => {
      //   this.$message({
      //     message: '指令发送成功',
      //     type: 'success'
      //   });
      // }).catch((msg) => {
      //   this.$message.error(msg);
      // });
    },
    get0X03Hex(param){
      let macList = param.macList;
      let scanTime = param.scanTime
      let matches  = param.matches;
      let continuousTimes =  param.continuousTimes

      let macListCount = 0
      if(macList){
        macListCount = macList.split("\n").length;
      }

      macList = macList.replaceAll("\n","")
      macList = macList.replaceAll(" ","")
      macList = macList.replaceAll(":","")
      let hex = this.toHexString(scanTime,2)
          + this.toHexString(matches,2)
          + this.toHexString(continuousTimes,2)
          + this.toHexString(macListCount,2)
          + macList
      ;

      return hex;
    },
    get0X63Hex(param){
      let ledFlashingMode = param.ledFlashingMode
      let redLedSwitch = param.redLedSwitch
      let blueLedSwitch = param.blueLedSwitch
      let yellowLedSwitch = param.yellowLedSwitch
      let hex = this.toHexString(ledFlashingMode,2)
      hex += this.toHexString(redLedSwitch?1:0,2);
      hex += this.toHexString(yellowLedSwitch?1:0,2);
      hex += this.toHexString(blueLedSwitch?1:0,2);
      return hex;
    },
    toHexString(intVal,len){
      let hex = intVal.toString(16);
      if(hex.length < len){
        hex = "0" + hex;
      }
      return hex;
    },
    getBCDTime(){
      return "230614235500";
    }
  }
}
</script>

<style scoped>

</style>
